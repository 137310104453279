
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Feature extends Vue {
  @Prop() title: string;

  @Prop() description: string;

  @Prop() icon: string;
}
