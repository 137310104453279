
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter, State } from 'vuex-class';
import { BotStatusState, GlobalBotStats } from '@/types/State/BotStatusState';
import Button from '@/components/layout/Button.vue';
import ButtonRouterLink from '@/components/layout/ButtonRouterLink.vue';
import InviteBtn from '@/components/layout/InviteBtn.vue';

const namespace = 'botStatusStateModule';

@Component({
  components: {
    Button, ButtonRouterLink, InviteBtn,
  },
})
export default class Jumbotron extends Vue {
  @State('botStatusStateModule') botStatusStateModule: BotStatusState;

  @Action('fetchGlobalData', { namespace }) fetchGlobalData: () => void;

  @Getter('globalStats', { namespace }) globalStats: GlobalBotStats;

  isMobile = false;

  beforeDestroy(): void {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize);
    }
  }

  onResize(): void {
    this.isMobile = window.innerWidth < 767;
  }

  mounted(): void {
    this.onResize();
    window.addEventListener('resize', this.onResize, { passive: true });
    this.fetchGlobalData();
  }
}
