import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from '@/types/State/RootState';
import { artsStateModule } from './arts/index';
import { commandsStateModule } from './commands/index';
import { botStatusStateModule } from './status/index';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: {
    version: '1.1.78',
    activePage: '/',
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    commandsStateModule,
    artsStateModule,
    botStatusStateModule,
  },
};

export default new Vuex.Store<RootState>(store);
