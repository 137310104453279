
import { Component, Vue } from 'vue-property-decorator';
import { ackeeTracker } from '@/utils/ackee';

@Component
export default class InviteBtn extends Vue {
  btnClick() {
    ackeeTracker.action('18385a46-1869-48c3-93aa-88e04fa59136', { key: 'Click', value: 1 });
  }
}
