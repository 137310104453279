
import vfmPlugin from 'vue-final-modal';
import { Component, Vue } from 'vue-property-decorator';
import DisplayPopups from '@/components/layout/Popups/DisplayPopups.vue';
import Footer from '@/components/layout/Footer.vue';
import Navbar from '@/components/layout/Navbar/Navbar.vue';
import VueOnlineProp from '@/utils/vue-online-prop';

Vue.use(VueOnlineProp);
Vue.use(vfmPlugin);

@Component({
  components: {
    Navbar, Footer, DisplayPopups,
  },
  metaInfo: {
    titleTemplate: 'Martine %s',
  },
})
export default class App extends Vue { }
