
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Button extends Vue {
  @Prop({ default: 'primary' })
    variant!: string;

  @Prop({ required: false })
    href!: string;

  @Prop({ required: true })
    text: string;

  @Prop({ default: false })
    openExternally: boolean;
}
