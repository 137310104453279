
import { Component, Vue } from 'vue-property-decorator';
import { navBarTabs } from '@/utils/constants';
import Button from '@/components/layout/Button.vue';
import NavLink from './NavLink.vue';

@Component({
  components: {
    Button,
    NavLink,
  },
})
export default class Navbar extends Vue {
  data = navBarTabs;

  get activePage(): number {
    return this.$store.state.activePage;
  }
}
