import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import VueMeta from 'vue-meta';
import store from '@/store/index';

import Home from '@/views/Home.vue';

Vue.use(VueRouter);
Vue.use(VueMeta);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/commands',
    name: 'Commands',
    component: () => import(/* webpackChunkName: "commands" */ '../views/Commands.vue'),
  },
  {
    path: '/art',
    name: 'Art',
    component: () => import(/* webpackChunkName: "art" */ '../views/Art.vue'),
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/premium',
    name: 'Premium',
    component: () => import(/* webpackChunkName: "premium-tiers" */ '../views/Premium/Tiers.vue'),
  },
  {
    path: '/premium/link',
    name: 'Premium Link',
    component: () => import(/* webpackChunkName: "premium-link" */ '../views/Premium/Link.vue'),
  },
  {
    path: '/status',
    name: 'Status',
    component: () => import(/* webpackChunkName: "status" */ '../views/Status.vue'),
  },
  {
    path: '/privacy-policy/bot',
    alias: '/privacy-policy',
    name: 'Bot Privacy Policy',
    component: () => import(/* webpackChunkName: "bot-privacy-policy" */ '../views/Terms/PrivacyPolicy/Bot.vue'),
  },
  {
    path: '/privacy-policy/website',
    name: 'Website Privacy Policy',
    component: () => import(/* webpackChunkName: "website-privacy-policy" */ '../views/Terms/PrivacyPolicy/Website.vue'),
  },
  {
    path: '/terms-of-service',
    name: 'Terms of Service',
    component: () => import(/* webpackChunkName: "terms-of-service" */ '../views/Terms/TermsofService/TermsofService.vue'),
  },
  {
    path: '/bot-rules',
    name: 'Bot Rules',
    component: () => import(/* webpackChunkName: "bot-rules" */ '../views/Rules.vue'),
  },
  {
    path: '/thanks',
    name: 'Thanks',
    component: () => import(/* webpackChunkName: "thanks" */ '../views/Thanks.vue'),
    beforeEnter: (to, _from, next) => {
      if (Object.keys(to.query).length > 0) { next('/thanks'); } else { next(); }
    },
  },
  {
    path: '/imagereports',
    name: 'ImageReports',
    component: () => import(/* webpackChunkName: "imagereports" */ '../views/ImageReports/Prompt.vue'),
  },
  {
    path: '/imagereports/callback',
    name: 'ImageReportsCallback',
    component: () => import(/* webpackChunkName: "imagereportscallback" */ '../views/ImageReports/Callback.vue'),
  },
  {
    path: '/upvote',
    name: 'UpvoteBase',
    component: () => import(/* webpackChunkName: "upvote" */ '../views/Redirect.vue'),
    props: { redirectURL: 'https://top.gg/bot/512227974893010954/vote' },
  },
  {
    path: '/upvote/top.gg',
    name: 'UpvoteTopgg',
    component: () => import(/* webpackChunkName: "upvote" */ '../views/Redirect.vue'),
    props: { redirectURL: 'https://top.gg/bot/512227974893010954/vote' },
  },
  {
    path: '/upvote/dbl',
    name: 'UpvoteDBL',
    component: () => import(/* webpackChunkName: "upvote" */ '../views/Redirect.vue'),
    props: { redirectURL: 'https://discordbotlist.com/bots/martine/upvote' },
  },

  // 404 not found
  {
    path: '*',
    name: 'Not Found',
    component: () => import(/* webpackChunkName: "nope" */ '../views/NotFound.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0, behavior: 'smooth' };
  },
});

router.beforeEach((to, _from, next) => {
  store.state.activePage = to.path;
  next();
});

export default router;
