
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class NavLink extends Vue {
  @Prop() link: string;

  @Prop() activePage: string;

  @Prop({ default: null })
    icon: string;

  @Prop() text: string;

  @Prop({ default: true })
    isRouterLink: boolean;

  @Prop({ default: false })
    openExternally: boolean;
}
