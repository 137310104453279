
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ButtonRouterLink extends Vue {
  @Prop({ default: 'primary' })
    variant!: string;

  @Prop({ required: true })
    to!: string;

  @Prop({ required: true })
    text: string;
}
