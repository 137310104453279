
import { Component, Vue } from 'vue-property-decorator';
import { botFeatures } from '@/utils/constants';
import InviteBtn from '@/components/layout/InviteBtn.vue';
import Feature from './Feature.vue';

@Component({
  components: {
    Feature, InviteBtn,
  },
})
export default class FeaturesSection extends Vue {
  data = botFeatures;
}
